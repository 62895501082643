import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ROUTES } from '../constants/routes';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { solarizedlight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { PRIVATE_ROUTES } from '../constants/private_route';
import Footer from './Footer';

function MainContent({ content: initialContent }) {
  const [content, setContent] = useState(initialContent);
  const { page_title } = useParams();

  useEffect(() => {
    const loadContent = async () => {
      if (!page_title) return;

      let route = ROUTES.find(r => r.path === `/${page_title}`);
      if(!route) {
        route = PRIVATE_ROUTES.find(r => r.path === `/f/${page_title}` || r.path === `/page/${page_title}`);
      }

      let module;
      try {
        const module = await import(`../data/${route.name.toLowerCase()}.json`);
        setContent(module.default);
      } catch (error) {
        try {
          module = await import(`../data/${page_title.toLowerCase()}.json`);
          setContent(module.default);
        } catch (innerError) {
          console.error("Failed to import module:", innerError);
          setContent({ error: 'Page not found' });
        }
      }
    };

    if (page_title) {
      loadContent();
    }
  }, [page_title]);

  if (!content) return <div>Loading...</div>;
  if (content.error) return <div>{content.error}</div>;

  // const processContent = (text) => {
  //   // Convert anchor tags to Markdown links
  //   text = text.replace(/<a\s+(?:[^>]*?\s+)?href="([^"]*)"[^>]*>(.*?)<\/a>/g, '[$2]($1)');
    
  //   // Convert triple backtick code blocks to Markdown code blocks
  //   text = text.replace(/```\n([\s\S]*?)```/g, (match, code) => {
  //     return '```\n' + code.trim() + '\n```';
  //   });

  //   // Convert single backtick inline code to Markdown inline code
  //   text = text.replace(/`([^`\n]+)`/g, '`$1`');

  //   return text;
  // };
  // Helper function to render content based on its type
  const renderContent = (item) => {
    if (typeof item === 'string') {
      // Check if the item is an anchor tag
      // Check if the item contains HTML
      if (item.includes('<') && item.includes('>')) {
        return <span dangerouslySetInnerHTML={{ __html: item }} />;
      }
      if (item.startsWith('<a') && item.endsWith('</a>')) {
        return <span dangerouslySetInnerHTML={{ __html: item }} />;
      }
      // For other content, use ReactMarkdown
      return (
        <ReactMarkdown
          components={{
            code({node, inline, className, children, ...props}) {
              const match = /language-(\w+)/.exec(className || '')
              return !inline && match ? (
                <SyntaxHighlighter
                  style={solarizedlight}
                  language={match[1]}
                  PreTag="div"
                  {...props}
                >
                  {String(children).replace(/\n$/, '')}
                </SyntaxHighlighter>
              ) : (
                <code className={className} {...props}>
                  {children}
                </code>
              )
            }
          }}
        >
          {item}
        </ReactMarkdown>
      );
    }
    if (Array.isArray(item)) {
      return (
        <ul>
          {item.map((point, index) => (
            <li key={index}>{renderContent(point)}</li>
          ))}
        </ul>
      );
    }
    return null;
  };

  return (
    <div className="content-wrapper">
      <h1>{content.name}</h1>
      {content.image && <img src={content.image} alt="Profile" className="profile-image" />}
      <p>{content.description}</p>
      {content.email && <p>Email: <a href={`mailto:${content.email}`}>{content.email}</a></p>}
      {content.interests && (
        <>
          <h2>Things I'm involved with and areas of interest:</h2>
          <ul>
            {content.interests.map((item, index) => (
              <li key={index} dangerouslySetInnerHTML={{ __html: item }} />
            ))}
          </ul>
        </>
      )}
      {content.elsewhere && (
        <>
          <h2>Elsewhere</h2>
          <p dangerouslySetInnerHTML={{ __html: content.elsewhere }} />
        </>
      )}

      {content.sections && content.sections.map((section, index) => (
        <div key={index}>
          <h2>{section.title}</h2>
          {renderContent(section.points)}
        </div>
      ))}

      <Footer />
    </div>
  );
}

export default MainContent;