export const PRIVATE_ROUTES = [
    {
      name: 'Latika',
      path: '/f/latika',
      description: 'Wifey',
      displayTitle: 'Latika'
    },
    {
      name: 'Bookmarks',
      path: '/f/links',
      description: 'Bookmarks',
      displayTitle: 'Bookmarks'
    },
    {
      name: 'Why',
      path: '/page/why-i-do-what-i-do',
      description: 'Why I do what I do',
      displayTitle: 'why'
    },
];