import React, { useState, useEffect } from 'react';
import { formatDistanceToNow, format, isAfter, subHours, parseISO } from 'date-fns';

const NOTES_PER_PAGE = 10;

function Notes() {
  const [notes, setNotes] = useState([]);
  const [newNote, setNewNote] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const storedNotes = JSON.parse(localStorage.getItem('notes') || '[]');
    setNotes(storedNotes);
  }, []);

  const saveNotes = (updatedNotes) => {
    localStorage.setItem('notes', JSON.stringify(updatedNotes));
    setNotes(updatedNotes);
  };

  const addNote = (e) => {
    e.preventDefault();
    if (!newNote.trim()) return;
    const noteObject = {
      id: Date.now(),
      content: newNote,
      isLink: newNote.startsWith('http://') || newNote.startsWith('https://'),
      isStrikeThrough: false,
      createdAt: new Date().toISOString()
    };
    saveNotes([noteObject, ...notes]);
    setNewNote('');
    setCurrentPage(1); // Go to first page when a new note is added
  };

  const deleteNote = (id) => {
    const updatedNotes = notes.filter(note => note.id !== id);
    saveNotes(updatedNotes);
    if (currentPage > Math.ceil(updatedNotes.length / NOTES_PER_PAGE)) {
      setCurrentPage(prev => prev - 1);
    }
  };

  const toggleStrikeThrough = (id) => {
    saveNotes(notes.map(note => 
      note.id === id ? { ...note, isStrikeThrough: !note.isStrikeThrough } : note
    ));
  };

  const formatTimestamp = (timestamp) => {
    try {
      const date = parseISO(timestamp);
      const now = new Date();
      const twoHoursAgo = subHours(now, 2);

      if (isAfter(date, twoHoursAgo)) {
        return formatDistanceToNow(date, { addSuffix: true });
      } else {
        return format(date, 'dd/MM/yyyy hh:mm a');
      }
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Invalid date';
    }
  };

  const pageCount = Math.ceil(notes.length / NOTES_PER_PAGE);
  const paginatedNotes = notes.slice(
    (currentPage - 1) * NOTES_PER_PAGE,
    currentPage * NOTES_PER_PAGE
  );

  return (
    <div className="notes-container">
      <h1>My Notes</h1>
      <form onSubmit={addNote}>
        <textarea
          value={newNote}
          onChange={(e) => setNewNote(e.target.value)}
          placeholder="Enter a new note or link"
          rows="3"
        />
        <button type="submit">Add Note</button>
      </form>
      <ul className="notes-list">
        {paginatedNotes.map(note => (
          <li key={note.id} className={note.isStrikeThrough ? 'strike-through' : ''}>
            <input
              type="checkbox"
              checked={note.isStrikeThrough}
              onChange={() => toggleStrikeThrough(note.id)}
            />
            <div className="note-content-wrapper">
              <span className="note-content">
                {note.isLink ? (
                  <a href={note.content} target="_blank" rel="noopener noreferrer">{note.content}</a>
                ) : (
                  note.content
                )}
              </span>
              <span className="note-timestamp">{formatTimestamp(note.createdAt)}</span>
            </div>
            <button onClick={() => deleteNote(note.id)} className="delete-btn" aria-label="Delete note">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                <path fill="none" d="M0 0h24v24H0z"/>
                <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"/>
              </svg>
            </button>
          </li>
        ))}
      </ul>
      {pageCount > 1 && (
        <div className="pagination">
          <button 
            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span>{currentPage} / {pageCount}</span>
          <button 
            onClick={() => setCurrentPage(prev => Math.min(prev + 1, pageCount))}
            disabled={currentPage === pageCount}
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
}

export default Notes;