// src/components/Footer.js
import React, { useState } from 'react';
import ToastMessage from './ToastMessage';
import { subscribeUser } from '../services/subscribeService';

function Footer() {
  const [email, setEmail] = useState('');
  const [toast, setToast] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await subscribeUser(email);
      if (result.success) {
        setToast('Thanks for subscribing!');
      } else {
        setToast('Subscription failed. Please try again.');
      }
    } catch (error) {
      console.error('Error during subscription:', error);
      setToast('An error occurred. Please try again later.');
    }
    setEmail('');
  };

  return (
    <footer className="site-footer">
      <div className="footer-content">
        <form onSubmit={handleSubmit} className="subscribe-form">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email address"
            required
          />
          <button type="submit">Subscribe</button>
        </form>
      </div>
      {toast && (
        <ToastMessage 
          message={toast} 
          onClose={() => setToast(null)}
        />
      )}
    </footer>
  );
}

export default Footer;