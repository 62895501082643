export const ROUTES = [
    {
      name: 'About',
      path: '/about',
      description: 'Information about Prashant Sudeep',
      displayTitle: 'About Prashant Sudeep',
      requiresAuth: false,
      is_hidden: false,
    },
    {
      name: 'Advice',
      path: '/advice',
      description: 'Advice from Prashant Sudeep',
      displayTitle: 'Advice',
      requiresAuth: false,
      is_hidden: false,
    },
    {
      name: 'Blog',
      path: '/blog',
      description: 'Prashant Sudeep\'s blog posts',
      displayTitle: 'Blog',
      requiresAuth: false,
      is_hidden: false,
    },
    {
        name: 'Produtive',
        path: '/productive',
        description: 'How to be productive',
        displayTitle: 'Produtive',
        requiresAuth: false,
        is_hidden: false,
      },
      {
        name: 'Coding',
        path: '/coding',
        description: 'Why Everyone Should Learn Coding',
        displayTitle: 'Coding',
        requiresAuth: false,
        is_hidden: false,
      },
    // ... add more routes as needed
    {
        name: 'Notes',
        path: '/n/notes',
        description: 'Personal notes',
        displayTitle: 'My Notes',
        requiresAuth: false,
        is_hidden: false,
    },
    {
        name: 'Secrets',
        path: '/n/secrets',
        description: 'Secrets/password',
        displayTitle: 'Secret Manager',
        requiresAuth: false,
        is_hidden: false,
    },
    {
      name: 'Links',
      path: '/links',
      description: 'Bookmarks',
      displayTitle: 'Bookmarks',
      requiresAuth: false,
      is_hidden: false,
    },
    {
      name: 'Parenting',
      path: '/parenting',
      description: 'Parenting',
      displayTitle: 'Parenting',
      requiresAuth: false,
      is_hidden: true,
    },
    {
      name: 'Write',
      path: '/write',
      description: 'Write something here',
      displayTitle: 'Write',
      requiresAuth: true,
      is_hidden: true,
    },
    {
      name: 'Mental-Junk',
      path: '/mental-junk',
      description: 'Mental Junk',
      displayTitle: 'Write',
      requiresAuth: false,
      is_hidden: true,
    }
  ];
  
  export const HOME_ROUTE = {
    name: 'Home',
    path: '/',
    description: 'Prashant Sudeep\'s homepage',
    displayTitle: 'Prashant Sudeep'
  };

  export const LOGIN_ROUTE = {
    name: 'Login',
    path: '/auth/login',
    description: 'Login page',
    displayTitle: 'Login'
  };