// src/services/subscribeService.js
import axios from 'axios';

const API_URL = 'https://api.rishant.tech/subscribe';

export const subscribeUser = async (email) => {
  try {
    const headers = {
      'X-Request-Source': 'WebApp',
      'X-App-Version': process.env.REACT_APP_VERSION || '1.0.0',
      'X-Host': window.location.hostname,
    };

    const response = await axios.post(API_URL, { email }, { headers });
    
    if (response.status === 200) {
      return { success: true, message: 'Subscription successful' };
    } else {
      return { success: false, message: 'Subscription failed' };
    }
  } catch (error) {
    console.error('Error in subscription:', error);
    return { success: false, message: 'An error occurred during subscription' };
  }
};