// src/services/authService.js
import axios from 'axios';

const TOKEN_EXPIRATION_DAYS = 10; // Configurable: set to 10 days or 20 days as needed
const TOKEN_CHECK_INTERVAL = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

export const setAuthToken = (token) => {
    const expirationDate = new Date(new Date().getTime() + TOKEN_EXPIRATION_DAYS * 24 * 60 * 60 * 1000);
    localStorage.setItem('authToken', token);
    localStorage.setItem('token', token);
    localStorage.setItem('tokenExpiration', expirationDate.toISOString());
};

export const getAuthToken = () => {
    const token = localStorage.getItem('authToken');
    const expiration = new Date(localStorage.getItem('tokenExpiration'));
    
    if (token && expiration > new Date()) {
        return token;
    } else {
        removeAuthToken();
        return null;
    }
};

export const removeAuthToken = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('token');
    localStorage.removeItem('tokenExpiration');
    localStorage.removeItem('accType');
    localStorage.removeItem('email');
    localStorage.removeItem('client');
    localStorage.removeItem('userDetails');
};

export const setUserDetails = (userDetails) => {
    localStorage.setItem('accType', userDetails.accType);
    localStorage.setItem('email', userDetails.email);
    localStorage.setItem('client', userDetails.queryType);
    localStorage.setItem('userDetails', JSON.stringify(userDetails));
};

export const getUserDetails = () => {
    const userDetails = localStorage.getItem('userDetails');
    return userDetails ? JSON.parse(userDetails) : null;
};

export const getEmail = () => {
    return localStorage.getItem('email') || null;
};

export const checkTokenValidity = async () => {
    const token = getAuthToken();
    if (!token) {
        return false;
    }

    try {
        // Replace 'YOUR_API_ENDPOINT' with your actual API endpoint
        let API_ENDPOINT = process.env.REACT_APP_API_URL;
        const response = await axios.get(`${API_ENDPOINT}/validate-token`, {
            headers: { Authorization: `Bearer ${token}` }
        });
        return response.data.isValid;
    } catch (error) {
        console.error('Error checking token validity:', error);
        return false;
    }
};

export const startTokenValidityCheck = () => {
    setInterval(async () => {
        const isValid = await checkTokenValidity();
        if (!isValid) {
            removeAuthToken();
            // Optionally, redirect to login page or show a notification
            window.location.href = '/login';
        }
    }, TOKEN_CHECK_INTERVAL);
};

// Call this function when your app initializes
export const initializeAuth = () => {
    startTokenValidityCheck();
};